import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full'
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'anagrafica',
    loadChildren: () => import('./pages/anagrafica/anagrafica.module').then( m => m.AnagraficaPageModule)
  },
  {
    path: 'questionario',
    loadChildren: () => import('./pages/questionario/questionario.module').then( m => m.QuestionarioPageModule)
  },
  {
    path: 'conclusione',
    loadChildren: () => import('./pages/conclusione/conclusione.module').then( m => m.ConclusionePageModule)
  },
  {
    path: 'finale',
    loadChildren: () => import('./pages/finale/finale.module').then( m => m.FinalePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
